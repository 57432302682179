import React from "react";

import './App.css';

import {Login} from './components/Users/Login.js';


function App() {
    return (
    <>
      <Login/>
    </>
  )
}

export default App;
